<template>
  <!-- loader Start -->
  <div id="loading" class="d-flex align-items-center justify-content-center">
    <div id="loading-center" class="d-flex align-items-center justify-content-center shadow">
      <span></span>
      <img src="@/assets/images/main-logo.jpg">
    </div>
  </div>
  <!-- loader END -->
</template>
<script>
export default {
  name: 'Loader',
  props: {
    dark: { type: Boolean, default: false }
  },
  computed: {},
  mounted () {
  }
}
</script>
<style>

#loading{
  /* From https://css.glass */
background: rgba(255, 255, 255, 0.25);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);
border: 1px solid rgba(255, 255, 255, 0.3);
}

#loading-center {
  background-color: white;
  border-radius: 50px;
  width: 75px;
  height: 75px;
  position: relative;
}

#loading-center span {
  width: 100%;
  height: 100%;
  display: inline-block;
  border-radius: 50px;
  position: absolute;
  top: 0px;
  left: 0px;
  border-top: 2px solid var(--primary);
  border-left: 2px solid var(--primary);
  animation: rotate 1s linear infinite;
}

#loading img {
  width: 35px;
  animation: fadIn 1s linear infinite;
  animation-direction: alternate-reverse;
}

@keyframes fadIn {
    from{
      opacity: 0;
    }
    to{
      opacity: 1;
    }
}

@keyframes rotate {
    from{
      transform: rotate(0);
    }
    to{
      transform: rotate(360deg);
    }
}

</style>
